/*
 * @file Interpolate a url template with a given set of options
 * the interpolation is done on ${...} similar to javascript template literals
 * @example apiUrlInterpolate('/api/${API_VERSION}/libraries/${libraryId}/billing/invoices', { API_VERSION: 1, libraryId: 123 }) => '/api/1/libraries/123/billing/invoices'
 */

export function apiUrlInterpolate(urlTemplate: string, options: Record<string, any>): string {
  return urlTemplate.replace(/\${\s*([\s\S]+?)\s*}/g, (match, key) => {
    const trimmedKey = key.trim()
    const value = trimmedKey.split('.').reduce((obj, prop) => obj && obj[prop], options)
    return value !== undefined ? value.toString() : match
  })
}
